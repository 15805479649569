import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
// import Dashboard from "./scenes/dashboard";
import JSONEscape from "./scenes/json/escape";
import JSONCSVConverter from "./scenes/json/csvconverter";
// import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import JSONValidator from "./scenes/json/validator";
import JSONXMLConverter from "./scenes/json/xmlconverter";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import PrivacyPolicy from "./scenes/privacy";
import Home from "./scenes/home";
import Footer from "./scenes/global/Footer";
import ContactPage from "./scenes/global/Contact";
import URLEncoder from "./scenes/url/encoder";
import URLSanitizer from "./scenes/url/sanitize";
import URLBuilder from "./scenes/url/builder";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* <TodoBoard /> */}
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Analytics/>
            <SpeedInsights/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/json/escape" element={<JSONEscape />} />
              <Route path="/json/validator" element={<JSONValidator />} />
              <Route path="/json/csv-converter" element={<JSONCSVConverter />} />
              <Route path="/json/xml-converter" element={<JSONXMLConverter />} />
              <Route path="/url/encode" element={<URLEncoder />} />
              <Route path="/url/sanitize" element={<URLSanitizer />} />
              <Route path="/url/builder" element={<URLBuilder />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
