import { Box, Typography, useTheme, TextField, Button, IconButton, Link, Divider } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";

const URLBuilder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [baseURL, setBaseURL] = useState('');
  const [params, setParams] = useState([{ key: '', value: '' }]);
  const [constructedURL, setConstructedURL] = useState('');
  const [error, setError] = useState('');

  const handleAddParam = () => {
    setParams([...params, { key: '', value: '' }]);
  };

  const handleRemoveParam = (index) => {
    const newParams = [...params];
    newParams.splice(index, 1);
    setParams(newParams);
  };

  const updateParamKey = (index, newKey) => {
    const newParams = [...params];
    newParams[index].key = newKey;
    setParams(newParams);
  };

  const updateParamValue = (index, newValue) => {
    const newParams = [...params];
    newParams[index].value = newValue;
    setParams(newParams);
  };

  const buildURL = () => {
    try {
      let url = new URL(baseURL);
      params.forEach(param => {
        if (param.key && param.value) {
          url.searchParams.set(param.key, param.value);
        }
      });
      setConstructedURL(url.href);
      setError('');  // Clear any existing errors when build is successful
    } catch (e) {
      setError(`Invalid URL: ${e.message}`);
      setConstructedURL('');
    }
  };

  return (
    <Box m="20px">
      <Header title="Dynamic URL Builder" subtitle="Construct URLs with Query Parameters" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <TextField
            fullWidth
            label="Base URL"
            value={baseURL}
            onChange={(e) => setBaseURL(e.target.value)}
            placeholder="Enter the base URL"
            margin="normal"
            variant="outlined"
            sx={{ backgroundColor: colors.primary[400] }}
        />
        {params.map((param, index) => (
            <Box key={index} display="flex" alignItems="center" mt={2}>
            <TextField
                label="Key"
                value={param.key}
                onChange={(e) => updateParamKey(index, e.target.value)}
                placeholder="Parameter key"
                sx={{ mr: 1, backgroundColor: colors.primary[400] }}
            />
            <TextField
                label="Value"
                value={param.value}
                onChange={(e) => updateParamValue(index, e.target.value)}
                placeholder="Parameter value"
                sx={{ mr: 1, backgroundColor: colors.primary[400] }}
            />
            <IconButton onClick={() => handleRemoveParam(index)} color="error">
                <DeleteIcon />
            </IconButton>
            </Box>
        ))}
        <Button variant="contained" onClick={handleAddParam} sx={{ mt: 2, backgroundColor: colors.greenAccent[700] }}>
            Add Parameter
        </Button>
        <Button variant="contained" onClick={buildURL} sx={{ mt: 2, ml: 2, backgroundColor: colors.blueAccent[700] }}>
            Build URL
        </Button>
        {error && (
            <Typography color="error" sx={{ mt: 4 }}>{error}</Typography>
        )}
        {constructedURL && (
            <TextField
              fullWidth
              label="Constructed URL"
              value={constructedURL}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              variant="outlined"
              sx={{ mt: 4, backgroundColor: colors.primary[400] }}
            />
        )}

        <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            Dynamic URL Builder
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Enhance your marketing and analytics efforts with our Dynamic URL Builder. This tool is essential for digital marketers, SEO specialists, and webmasters who wish to track the effectiveness of their campaigns through customized URLs. It allows users to add campaign parameters to URLs so that customized tracking can be integrated with analytics tools like Google Analytics.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Why Use Our Dynamic URL Builder?
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="body1" paragraph>
            Our Dynamic URL Builder simplifies the creation of UTM parameters, enabling precise tracking of user engagement and campaign performance. It helps you generate URLs that are tailored to gather specific analytics data, improving the accuracy and effectiveness of your digital marketing strategies.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li><strong>Custom Parameter Integration:</strong> Easily add UTM parameters like source, medium, and campaign to monitor traffic sources accurately.</li>
              <li><strong>Simple and Intuitive Interface:</strong> Our tool is designed for efficiency, with a user-friendly interface that simplifies the URL building process.</li>
              <li><strong>Real-Time URL Preview:</strong> See how your final URL will appear as you add parameters, ensuring there are no errors before deployment.</li>
            </ul>
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            How to Use the Dynamic URL Builder
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            <strong>Base URL:</strong> Start by entering the base URL you wish to enhance.<br/>
            <strong>Add Parameters:</strong> Specify the UTM parameters you need for your campaign tracking.<br/>
            <strong>Generate URL:</strong> Click the 'Generate URL' button to create your customized tracking URL.<br/>
            <strong>Copy and Use:</strong> Copy the generated URL and use it in your marketing campaigns to track performance effectively.
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Discover more useful tools to boost your online presence and operational efficiency:
            <ul>
              <li><Link href="/url/encode" target="_blank" style={{ color: colors.greenAccent[500] }}>URL Encoder/Decoder</Link></li>
              <li><Link href="/url/sanitize" target="_blank" style={{ color: colors.greenAccent[500] }}>URL Sanitizer</Link></li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default URLBuilder;
