import { Box, Typography, useTheme, TextField, Button, Divider, Link } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";

const JSONValidator = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');

  const validateJSON = () => {
    try {
      const jsonObject = JSON.parse(input); // Parses the input to verify if it's valid JSON
      const prettyJSON = JSON.stringify(jsonObject, null, 2); // Formats it with indentation
      const compactJSON = JSON.stringify(jsonObject); // Compact representation without spaces
      setOutput({ pretty: prettyJSON, compact: compactJSON });
      setError('');
    } catch (e) {
      setError(`Error: ${e.message}`); // Displays the actual error message
      setOutput('');
    }
  };

  return (
    <Box m="20px">
      <Header title="JSON Validator" subtitle="Validate and Format JSON" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter JSON here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box display="flex" justifyContent="space-between" my={2}>
          <Button variant="contained" onClick={validateJSON} sx={{ backgroundColor: colors.greenAccent[700] }}>
            Validate JSON
          </Button>
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        <Typography sx={{ mt: 2, mb: 1 }}>Pretty JSON:</Typography>
        <TextField
          fullWidth
          multiline
          minRows={5}
          value={output.pretty}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          sx={{ backgroundColor: colors.primary[500]}}
        />
        <Typography sx={{ mt: 2, mb: 1 }}>Compact JSON:</Typography>
        <TextField
          fullWidth
          multiline
          minRows={5}
          value={output.compact}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          sx={{ backgroundColor: colors.primary[500]}}
        />
        <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            Fast and Reliable JSON Validator
          </Typography>
          <Divider sx={{ my: 2 }} />  {/* Horizontal line break after title */}

          <Typography variant="body1" paragraph>
            Welcome to ParseTxt, your go-to solution for all your JSON validation needs. Our JSON Validator is designed to help developers, programmers, and data enthusiasts easily validate and format JSON files with precision and speed. Whether you're working on a small project or handling large data sets, our tool ensures that your JSON data is syntactically correct and ready for use.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Why Use Our JSON Validator?
          </Typography>
          <Divider sx={{ my: 1 }} />  {/* Subtle break between section title and content */}

          <Typography variant="h3" component="h3" gutterBottom>
            Accuracy and Efficiency
          </Typography>
          <Typography variant="body1" paragraph>
            Our JSON Validator quickly identifies any errors or issues in your JSON data, from missing commas to syntactic inaccuracies. It provides detailed error messages and the exact location of the issue, making debugging faster and more efficient.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            User-Friendly Interface
          </Typography>
          <Typography variant="body1" paragraph>
            With a clean and straightforward interface, our JSON Validator allows you to easily paste your JSON code into the validation box and get instant results. No clutter, no fuss – just simple and direct functionality that saves you time and effort.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Features and Capabilities
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li><strong>Syntax Checking:</strong> Automatically detects and highlights syntax errors in real-time.</li>
              <li><strong>Code Beautification:</strong> Formats and beautifies your JSON code for better readability and organization.</li>
              <li><strong>Compatibility:</strong> Works seamlessly across all modern browsers and devices, ensuring you have access to our tool wherever you work.</li>
            </ul>
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            How to Use Our JSON Validator
          </Typography>
          <Divider sx={{ my: 2 }} />  {/* Emphasize separation before usage instructions */}

          <Typography variant="body1" paragraph>
            <strong>Input Your JSON:</strong> Simply paste your JSON code into the text box on our page.<br/>
            <strong>Validate JSON:</strong> Click the 'Validate JSON' button to start the validation process.<br/>
            <strong>Review Results:</strong> Get immediate feedback on the validity of your JSON. If there are errors, our tool will display a detailed description and the line number of each issue.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Integrated Tools for Enhanced Productivity
          </Typography>
          <Divider sx={{ my: 2 }} />  {/* Divider before listing additional tools */}

          <Typography variant="body1" paragraph>
            At ParseTxt, we offer more than just a JSON Validator. Check out our suite of tools designed to optimize your coding and data handling tasks:
            <ul>
              <li><Link href="/json/escape" target="_blank" color={colors.greenAccent[500]}>JSON Escape/Unescape</Link></li>
              <li><Link href="/json/csv-converter" target="_blank" color={colors.greenAccent[500]}>JSON to CSV Converter</Link></li>
              <li><Link href="/json/xml-converter" target="_blank" color={colors.greenAccent[500]}>JSON to XML Converter</Link></li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default JSONValidator;
