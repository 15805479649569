import { Box, Typography, useTheme, TextField, Button, Link, Divider } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import DOMPurify from 'dompurify';

const URLSanitizer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const sanitizeURL = () => {
    const cleanURL = DOMPurify.sanitize(input, {ALLOWED_TAGS: [], ALLOWED_ATTR: []});
    setOutput(cleanURL);
  };

  return (
    <Box m="20px">
      <Header title="URL Sanitizer" subtitle="Sanitize URLs to prevent XSS attacks" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter URL here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400] }}
        />
        <Box display="flex" justifyContent="space-between" my={2}>
        <Button variant="contained" onClick={sanitizeURL} sx={{ backgroundColor: colors.greenAccent[700] }}>
          Sanitize URL
        </Button>
        </Box>
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={output}
          InputProps={{
            readOnly: true,
          }}
          placeholder="Sanitized URL will be displayed here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400] }}
        />
        <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            URL Sanitizer
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Ensure safe web browsing with our URL Sanitizer. This tool is crucial for web developers, security analysts, and digital marketers who need to verify and cleanse URLs to prevent security threats like XSS attacks and ensure links are safe for user interaction. The URL Sanitizer checks and strips harmful elements from URLs, making them secure before use in web environments.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Why Use Our URL Sanitizer?
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="body1" paragraph>
            Our URL Sanitizer is designed to provide a robust defense against common web threats by sanitizing URLs to remove potentially malicious scripts and tags. It helps protect your web applications from various security vulnerabilities associated with unsafe URLs.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li><strong>Advanced Filtering:</strong> Employs advanced algorithms to detect and remove harmful components from URLs.</li>
              <li><strong>Real-Time Protection:</strong> Processes URLs in real time, providing immediate sanitation.</li>
              <li><strong>User-Friendly Interface:</strong> Easy-to-navigate interface allows for straightforward URL input and sanitation.</li>
            </ul>
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            How to Use the URL Sanitizer
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            <strong>Input URL:</strong> Enter the URL you need to sanitize into the input field.<br/>
            <strong>Sanitize:</strong> Click the 'Sanitize' button to clean the URL from any potential security threats.<br/>
            <strong>Results:</strong> View the sanitized URL and safely use or embed it in your projects.
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Explore other essential tools designed to enhance your online security and functionality:
            <ul>
              <li><Link href="/url/encode" target="_blank" style={{ color: colors.greenAccent[500] }}>URL Encoder/Decoder</Link></li>
              <li><Link href="/url/builder" target="_blank" style={{ color: colors.greenAccent[500] }}>Dynamic URL Builder</Link></li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default URLSanitizer;
