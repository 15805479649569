import React from 'react';
import { Box, Typography, Link, List, ListItem, ListItemText, Divider, Paper, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';


const Home = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  const toolItems = [
    {
        title: "JSON Validator",
        description: "Validates JSON strings/files against a schema or the JavaScript Object Notation standards. It helps in catching syntax errors, misplaced commas, and unwanted data types to ensure the JSON format is correct.",
        points: [
          "Checks JSON for errors and ensures format correctness",
          "Supports detailed error messages to pinpoint the exact location of issues",
          "Able to validate against custom JSON schemas",
          "Supports copy-paste or file upload"
        ],
        url: "/json/validator"
      },
      {
        title: "JSON Escape",
        description: "Escapes and unescapes JSON strings/files making them safe to use within strings in different programming environments. This tool is essential for embedding JSON into other code languages or preparing it for output.",
        points: [
          "Converts special characters into their escaped equivalents",
          "Ensures JSON strings can be safely embedded into HTML or JavaScript",
          "Supports copy-paste functionality for easy use",
          "Allows for easy transition between escaped and unescaped states"
        ],
        url: "/json/escape"
      },
      {
        title: "JSON CSV Converter",
        description: "Converts JSON data into a CSV format, which is great for exporting JSON files for use in spreadsheets or databases. The tool also allows converting back from CSV to JSON.",
        points: [
          "Easily convert between JSON and CSV formats",
          "Includes options for custom delimiters and headers",
          "Can handle nested JSON objects and arrays",
          "Supports file upload and can process large files"
        ],
        url: "/json/csv-converter"
      },
      {
        title: "JSON XML Converter",
        description: "Transforms JSON into XML format, enabling the integration of JSON data with applications that require XML. It supports various XML attributes and encoding styles.",
        points: [
          "Facilitates the conversion of JSON to XML without data loss",
          "Respects attribute and element distinctions in the XML output",
          "Provides options for pretty-printing or compact XML",
          "Supports direct input or file upload for user convenience"
        ],
        url: "/json/xml-converter"
      },
      {
        "title": "URL Encoder / Decoder",
        "description": "Encodes or decodes URLs to ensure they are compliant with web standards and can be safely transmitted over the internet. This tool is crucial for encoding data passed in URL parameters.",
        "points": [
            "Converts characters into a format that can be transmitted over the Internet",
            "Helps prevent URL manipulation by sanitizing data",
            "Supports encoding and decoding in real-time",
            "Simple copy-paste or file upload functionality"
        ],
        "url": "/url/encode"
    },
    {
        "title": "URL Sanitizer",
        "description": "Sanitizes URLs to remove potentially harmful parts that could expose the application to XSS or other injection attacks. Ideal for enhancing security in web applications.",
        "points": [
            "Removes dangerous URL parts like malicious scripts",
            "Ensures URLs are safe for use within HTML and web applications",
            "Critical for preventing XSS and other security threats",
            "Supports direct input for immediate sanitization"
        ],
        "url": "/url/sanitize"
    },
    {
        "title": "Dynamic URL Builder",
        "description": "Creates complex URLs dynamically by appending various query parameters. This tool is perfect for generating URLs for tracking campaigns, analytics, and more.",
        "points": [
            "Allows dynamic addition of multiple query parameters",
            "Enables creating customizable URLs for marketing and analytics",
            "Supports both URL encoding and decoding as parameters are added",
            "Interface supports adding, removing, and editing parameters easily"
        ],
        "url": "/url/builder"
    }
  ];

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h1" component="div" gutterBottom>
          ParseTxt
        </Typography>
        <Typography variant="h4" component="div" gutterBottom style={{marginBottom: '40px'}}>
          Welcome to ParseTxt! This is a collection of text parsing tools that can help you manipulate and analyze text data. Below are some of the tools that you can use:
        </Typography>
        {toolItems.map((tool, index) => (
          <Box key={index} mb={2}>
            <Typography variant="h2"><Link href={tool.url} color={colors.greenAccent[500]}>{tool.title}</Link></Typography>
            <Typography paragraph>{tool.description}</Typography>
            <List>
              {tool.points.map((point, idx) => (
                <ListItem key={idx} style={{ padding: '4px 0' }}>
                  <ListItemText primary={point} />
                </ListItem>
              ))}
            </List>
            {index < toolItems.length - 1 && <Divider />}
          </Box>
        ))}
      </Paper>
    </Container>
  );
};

export default Home;
