import React, { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme, Link, Divider } from '@mui/material';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';

const JSONCSVConverter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const flattenObject = (obj, parent = '', res = {}) => {
        for (let key in obj) {
          let propName = parent ? parent + '_' + key : key;
          if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
            flattenObject(obj[key], propName, res);
          } else if (Array.isArray(obj[key])) {
            res[propName] = obj[key].map(item => JSON.stringify(item)).join(";");
          } else {
            res[propName] = obj[key];
          }
        }
        return res;
      };
      
      const jsonToCSV = (json) => {
        try {
          const jsonObject = JSON.parse(json);
          const flattenedJson = Array.isArray(jsonObject) ? jsonObject.map(obj => flattenObject(obj)) : [flattenObject(jsonObject)];
          const keys = Object.keys(flattenedJson[0]);
          
          const replacer = (key, value) => value == null ? '' : value; // Replacer to handle null values
          
          const csv = flattenedJson.map(row => {
            return keys.map(fieldName => `"${JSON.stringify(row[fieldName], replacer).replace(/(^"|"$)/g, '')}"`).join(",");
          });
      
          csv.unshift(keys.join(",")); // add header column
          return csv.join("\r\n");
        } catch (e) {
          return `Invalid JSON input: ${e.message}`;
        }
      };
      
      

    const csvToJson = (csv) => {
        const lines = csv.split("\n");
        const keys = lines[0].split(",");
        return JSON.stringify(
            lines.slice(1).map(line => {
            const values = line.split(",");
            return keys.reduce((obj, key, index) => {
                obj[key] = values[index];
                return obj;
            }, {});
            }), null, 2);
    }

    const handleJSONToCSV = () => {
        setOutput(jsonToCSV(input));
    };

    const handleCSVToJSON = () => {
        setOutput(csvToJson(input));
    };

    return (
      <Box m="20px">
        <Header title="JSON CSV Converter" subtitle="Convert JSON to CSV and CSV to JSON" />
        <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <TextField
            label="Enter JSON/CSV here"
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            margin="normal"
            sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box display="flex" justifyContent="space-between" my={2}>
            <Button variant="contained" onClick={handleJSONToCSV} sx={{backgroundColor: colors.greenAccent[700]  }}>
            Convert to CSV
            </Button>
            <Button variant="contained" onClick={handleCSVToJSON} sx={{backgroundColor: colors.greenAccent[700]  }}>
            Convert to JSON
            </Button>
        </Box>
        <TextField
            label="Output"
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={output}
            InputProps={{
            readOnly: true,
            }}
            margin="normal"
            sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            JSON to CSV Converter
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Simplify data transformation with our JSON to CSV Converter. This tool is ideal for developers, data analysts, and content managers who need to seamlessly convert JSON data into a CSV format, making it easier to manipulate and analyze in spreadsheet applications.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Why Use Our JSON to CSV Converter?
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="body1" paragraph>
            Our JSON to CSV Converter is designed to provide a smooth conversion process that maintains data integrity. Whether you're dealing with simple or complex JSON structures, our tool ensures that your data is accurately translated into CSV without any data loss or corruption.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li><strong>Intuitive Interface:</strong> Easy-to-use, drag and drop interface allows for hassle-free conversions.</li>
              <li><strong>Batch Processing:</strong> Convert multiple JSON files to CSV at once, saving valuable time and effort.</li>
              <li><strong>Data Accuracy:</strong> Ensures that all JSON data structures are perfectly mapped to CSV, maintaining all relational structures.</li>
            </ul>
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            How to Use the JSON to CSV Converter
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            <strong>Upload Your JSON:</strong> Drag and drop your JSON file or paste your JSON text directly into the input box.<br/>
            <strong>Convert:</strong> Click the 'Convert to CSV' button to start the conversion process.<br/>
            <strong>Download CSV:</strong> Once the conversion is complete, download the resulting CSV file directly to your device.
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Discover more tools at ParseTxt that enhance your productivity and streamline your data handling tasks:
            <ul>
              <li><Link href="/json/validator" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON Validator</Link></li>
              <li><Link href="/json/escape" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON Escape/Unescape</Link></li>
              <li><Link href="/json/xml-converter" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON to XML Converter</Link></li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
    );
};

export default JSONCSVConverter;
