import React, { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme, Link, Divider } from '@mui/material';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';

const JSONXMLConverter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const jsonToXML = (json) => {
      try {
          const obj = JSON.parse(json);
          const formatXml = (xml, tab) => {
              let formatted = '', indent = '';
              xml.split(/>\s*</).forEach((node) => {
                  if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
                  formatted += indent + '<' + node + '>\r\n';
                  if (node.match(/^<?\w[^>]*[^\/]$/) && !node.startsWith("input")) indent += tab; // increase indent
              });
              return formatted.substring(1, formatted.length-3);
          };
  
          const builder = (obj, nodeName) => {
              let xml = "";
              if (Array.isArray(obj)) {
                  for (const item of obj) {
                      xml += `<${nodeName}>${builder(item, nodeName)}</${nodeName}>`;
                  }
                  return xml;
              } else if (typeof obj === 'object') {
                  for (const prop in obj) {
                      xml += `<${prop}>${builder(obj[prop], prop)}</${prop}>`;
                  }
                  return xml;
              } else {
                  return obj.toString();
              }
          };
  
          const unformattedXml = `<root>${builder(obj, 'root')}</root>`;
          return formatXml(unformattedXml, '\t'); // Pretty print with tab
      } catch (e) {
          return `Invalid JSON input: ${e.message}`;
      }
    };
  

    const xmlToJson = (xml) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, "application/xml");
      const jsonBuilder = (node) => {
          if (node.nodeType === 3) { // text node
              return node.nodeValue;
          }
          const result = {};
          if (node.hasChildNodes()) {
              for (let child of node.childNodes) {
                  const childName = child.nodeName;
                  if (child.nodeType !== 3) {
                      if (result[childName] == undefined) {
                          result[childName] = jsonBuilder(child);
                      } else {
                          if (result[childName].push == undefined) {
                              const old = result[childName];
                              result[childName] = [old];
                          }
                          result[childName].push(jsonBuilder(child));
                      }
                  }
              }
          }
          return result;
      };
      return JSON.stringify(jsonBuilder(xmlDoc), null, 2);
    };

    const handleJSONToXML = () => {
      setOutput(jsonToXML(input));
    };

    const handleXMLToJSON = () => {
      setOutput(xmlToJson(input));
    };

    return (
        <Box m="20px">
            <Header title="JSON XML Converter" subtitle="Convert JSON to XML and XML to JSON" />
            <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none",
            },
            "& .name-column--cell": {
                color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            }}
        >
            <TextField
                label="Enter JSON/XML here"
                multiline
                rows={10}
                fullWidth
                variant="outlined"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                margin="normal"
                sx={{ backgroundColor: colors.primary[400]}}
            />
            <Box display="flex" justifyContent="space-between" my={2}>
                <Button variant="contained" onClick={handleJSONToXML} sx={{backgroundColor: colors.greenAccent[700]  }}>
                Convert to XML
                </Button>
                <Button variant="contained" onClick={handleXMLToJSON} sx={{backgroundColor: colors.greenAccent[700]  }}>
                Convert to JSON
                </Button>
            </Box>
            <TextField
                label="Output"
                multiline
                rows={10}
                fullWidth
                variant="outlined"
                value={output}
                InputProps={{
                readOnly: true,
                }}
                margin="normal"
                sx={{ backgroundColor: colors.primary[400]}}
            />
            <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
                <Typography variant="h1" component="h1" gutterBottom>
                    JSON to XML Converter
                </Typography>
                <Divider sx={{ my: 2 }} />

                <Typography variant="body1" paragraph>
                    Transform JSON into XML with ease using our JSON to XML Converter. This tool is invaluable for developers and data specialists who need to convert JSON data into XML format for integration with various applications and services that require XML over JSON.
                </Typography>

                <Typography variant="h2" component="h2" gutterBottom>
                    Why Use Our JSON to XML Converter?
                </Typography>
                <Divider sx={{ my: 1 }} />

                <Typography variant="body1" paragraph>
                    Our JSON to XML Converter ensures a smooth and reliable conversion process, allowing you to transform JSON into well-structured XML without any hassle. It’s designed to handle both simple and complex JSON data, ensuring that your XML outputs are error-free and ready for use.
                </Typography>

                <Typography variant="h3" component="h3" gutterBottom>
                    Key Features
                </Typography>
                <Typography variant="body2" paragraph>
                    <ul>
                    <li><strong>Automatic Conversion:</strong> Simply input your JSON and the tool automatically converts it to XML.</li>
                    <li><strong>High Accuracy:</strong> Maintains the structure and data integrity of your original JSON when converting to XML.</li>
                    <li><strong>User-Friendly Interface:</strong> No complicated settings, just a straightforward process that saves you time and effort.</li>
                    </ul>
                </Typography>

                <Typography variant="h2" component="h2" gutterBottom>
                    How to Use the JSON to XML Converter
                </Typography>
                <Divider sx={{ my: 2 }} />

                <Typography variant="body1" paragraph>
                    <strong>Input JSON:</strong> Paste your JSON text or upload a JSON file.<br/>
                    <strong>Convert:</strong> Click the 'Convert to XML' button to start the conversion process.<br/>
                    <strong>Download XML:</strong> Download the XML file once the conversion is completed, directly to your device.
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="body1" paragraph>
                    Explore other tools designed to optimize your workflows at ParseTxt:
                    <ul>
                    <li><Link href="/json/validator" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON Validator</Link></li>
                    <li><Link href="/json/escape" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON Escape/Unescape</Link></li>
                    <li><Link href="/json/csv-converter" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON to CSV Converter</Link></li>
                    </ul>
                </Typography>
                </Box>
        </Box>
    </Box>
    );
};

export default JSONXMLConverter;
