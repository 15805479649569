import { Box, Typography, useTheme, TextField, Button, Divider, Link } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataTeam } from "../../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../../components/Header";
import { useState } from "react";

const JSONEscape = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleEscapeJSON = () => {
    try {
      // Convert JSON object to string then escape special characters
      const jsonObject = JSON.parse(input); // Parses input into JSON object
      const jsonString = JSON.stringify(jsonObject); // Converts JSON object to string
      const escapedString = jsonString.replace(/\\/g, '\\\\').replace(/"/g, '\\"'); // Escapes backslashes and quotes
      setOutput(escapedString);
    } catch (error) {
      setOutput('Invalid JSON input.');
    }
  };
  
  const handleUnescapeJSON = () => {
    try {
      // Convert escaped JSON string back to regular JSON format
      const unescapedInput = input.replace(/\\"/g, '"').replace(/\\\\/g, '\\'); // Unescapes quotes and backslashes
      const unescapedJson = JSON.parse(unescapedInput); // Parses the unescaped string into JSON object
      const prettyPrintedJSON = JSON.stringify(unescapedJson, null, 2); // Beautifies JSON
      setOutput(prettyPrintedJSON);
    } catch (error) {
      setOutput('Invalid JSON input.');
    }
  };
  
  return (
    <Box m="20px">
      <Header title="JSON Escape Tool" subtitle="Escape / Unescape JSON" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter JSON here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box display="flex" justifyContent="space-between" my={2}>
            <Button variant="contained" onClick={handleEscapeJSON} sx={{ backgroundColor: colors.greenAccent[700]  }}>
            Escape JSON
            </Button>
            <Button variant="contained" onClick={handleUnescapeJSON} sx={{ backgroundColor: colors.greenAccent[700] }}>
            Unescape JSON
            </Button>
        </Box>
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={output}
          InputProps={{
            readOnly: true,
          }}
          placeholder="Output will be displayed here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            JSON Escape/Unescape Tool
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Efficiently manage your JSON data with our JSON Escape/Unescape Tool. This utility allows developers and data enthusiasts to quickly convert JSON strings for embedding in web pages or other applications, and reversely, decode the escaped JSON back to its original form.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Why Use Our JSON Escape/Unescape Tool?
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="body1" paragraph>
            Our tool provides an essential functionality for those who need to ensure their JSON strings are safely formatted for transfer or storage, without losing any data integrity. It's designed to handle a variety of encoding scenarios, making it a versatile tool for numerous applications.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li><strong>Easy-to-use Interface:</strong> Simply paste your JSON code into the tool and choose to escape or unescape it with a single click.</li>
              <li><strong>Fast Processing:</strong> Our tool quickly processes your JSON strings, saving you time and hassle.</li>
              <li><strong>Accuracy:</strong> Ensures high accuracy in converting characters, preventing any data corruption or loss of information.</li>
            </ul>
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            How to Use the JSON Escape/Unescape Tool
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            <strong>Input Your JSON:</strong> Paste the JSON string that you want to escape or unescape into the input box.<br/>
            <strong>Select Action:</strong> Choose whether you want to escape or unescape the JSON string.<br/>
            <strong>Execute:</strong> Click the 'Process' button to execute the conversion and view the results instantly.
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            At ParseTxt, we offer more than just a JSON Escape/Unescape tool. Check out our suite of tools designed to optimize your coding and data handling tasks:
            <ul>
              <li><Link href="/json/validator" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON Validator</Link></li>
              <li><Link href="/json/csv-converter" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON to CSV Converter</Link></li>
              <li><Link href="/json/xml-converter" target="_blank" style={{ color: colors.greenAccent[500] }}>JSON to XML Converter</Link></li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default JSONEscape;
