import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import CodeIcon from "@mui/icons-material/Code";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import styled from "styled-components";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <RouterLink to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCollapsed, setIsCollapsed] = useState(isMobile); // Collapsed by default on mobile

  // Handle sidebar toggling
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  

  return (
    <Conatiner>
      <Box
        className="sideBar"
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={toggleSidebar}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h3" color={colors.grey[100]}>
                  <Link href="/">ParseTxt</Link>
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                JSON
              </Typography>
              <Item
              title="JSON Validator"
              to="/json/validator"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
              />
              <Item
                title="JSON Escape"
                to="/json/escape"
                icon={<CodeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
              title="JSON CSV Converter"
              to="/json/csv-converter"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
              />
              <Item
              title="JSON XML Converter"
              to="/json/xml-converter"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
              />

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                URL
              </Typography>
              <Item
              title="URL Encoder / Decoder"
              to="/url/encode"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
              />
              <Item
              title="URL Sanitizer"
              to="/url/sanitize"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
              />
              <Item
              title="Dynamic URL Builder"
              to="/url/builder"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
              />
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </Conatiner>
  );
};

export default Sidebar;

const Conatiner = styled.div`
  height: 100vh;
`;
