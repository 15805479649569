// ContactPage.jsx
import React, { useState, useEffect , useRef } from 'react';
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';


const ContactPage = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {

    console.log('Submitted: ', { email, message });
  };



  return (
    <Container>
      <Typography variant="h4" gutterBottom>Contact Us</Typography>
    <div className="googleFormContainer">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdzC6oEfcJYwYzCLhOLIUHgk9lLSOjgG_qrrfoWZbkJQQwgBQ/viewform?embedded=true"
        width="100%"
        title="Google Form">Loading…</iframe>
    </div>
    </Container>
  );
};

export default ContactPage;
