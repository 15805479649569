import { Box, Typography, useTheme, TextField, Button, Link, Divider } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useState } from "react";

const URLEncoder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleEncodeURL = () => {
    setOutput(encodeURIComponent(input)); // Encodes the input URL
  };

  const handleDecodeURL = () => {
    try {
      setOutput(decodeURIComponent(input)); // Decodes the input URL
    } catch (error) {
      setOutput('Invalid URL input.');
    }
  };

  return (
    <Box m="20px">
      <Header title="URL Encoder/Decoder" subtitle="Encode and Decode URLs" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter URL here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box display="flex" justifyContent="space-between" my={2}>
            <Button variant="contained" onClick={handleEncodeURL} sx={{ backgroundColor: colors.greenAccent[700] }}>
            Encode URL
            </Button>
            <Button variant="contained" onClick={handleDecodeURL} sx={{ backgroundColor: colors.greenAccent[700] }}>
            Decode URL
            </Button>
        </Box>
        <TextField
          fullWidth
          multiline
          minRows={10}
          value={output}
          InputProps={{
            readOnly: true,
          }}
          placeholder="Output will be displayed here"
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400]}}
        />
        <Box sx={{ mt: 4, lineHeight: '1.6', pb: 4 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            URL Encoder/Decoder
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Optimize your URLs with our URL Encoder/Decoder, a tool designed for web developers and digital marketers who need to handle URL data by encoding to ensure it adheres to web standards and decoding to make it readable and editable. This tool is essential for preparing URLs for web transmission and analyzing query strings.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Why Use Our URL Encoder/Decoder?
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="body1" paragraph>
            Our URL Encoder/Decoder offers a reliable and straightforward way to convert characters into a format that can be transmitted over the Internet. It ensures that all URLs are correctly formatted to avoid errors in web applications, providing a seamless and efficient way to manage web data.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li><strong>Complete Character Encoding/Decoding:</strong> Supports all standard URL characters to ensure comprehensive handling.</li>
              <li><strong>Simple User Interface:</strong> Easy-to-use, intuitive interface allows for quick encoding and decoding operations.</li>
              <li><strong>Enhances Web Compatibility:</strong> Prepares URLs for smooth transmission and functionality across different web platforms.</li>
            </ul>
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            How to Use the URL Encoder/Decoder
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            <strong>Input URL:</strong> Paste the URL you wish to encode or decode into the input field.<br/>
            <strong>Choose Action:</strong> Select whether to encode or decode the URL.<br/>
            <strong>Process:</strong> Click the 'Encode' or 'Decode' button to apply the action and view the results instantly.
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" paragraph>
            Check out additional tools designed to enhance your online activities:
            <ul>
              <li><Link href="/url/sanitizer" target="_blank" style={{ color: colors.greenAccent[500] }}>URL Sanitizer</Link></li>
              <li><Link href="/url/builder" target="_blank" style={{ color: colors.greenAccent[500] }}>Dynamic URL Builder</Link></li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default URLEncoder;
